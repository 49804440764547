#profile-header {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 40px;
}

.profile-pic {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 60px;
}

#profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

#bio {
  width: 60%;
  margin: 20px;
  text-align: center;
}

#profile-galleries-header {
  margin: 20px;
  font-weight: 400;
}

#profile-galleries-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#profile-galleries-list > li {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

#profile-galleries-list p,
#profile-galleries-list a {
  text-align: center;
}

.follow-links {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

.follow-links a {
  text-decoration: none;
  color: black;
  text-align: center;
  justify-content: center;
}

.follow-links a:hover {
  text-decoration: underline;
}

.follow-links span {
  font-weight: 500;
}
