#gallery-password-form {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 32px;
  border-radius: 35px;
  padding: 20px;
  margin: 40px;
  align-items: center;
  position: relative;
  top: 50px;
}

#gallery-password-form > label,
#gallery-password-form > p,
#gallery-password-form > h2 {
  margin: 12px 0;
}
