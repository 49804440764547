@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@200;300;400&display=swap");
.hidden {
  display: none;
}

#navbar {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.2s ease-in;
}

.navbar-section {
  width: 155px;
}

#navbar > div {
  margin: 0 20px;
  display: flex;
  justify-content: flex-end;
}

.navbar-home-page {
  position: fixed;
}

.navbar-other {
  position: sticky;
  background-color: lightgray;
}

.navbar-home-page button {
  color: white;
  background-color: black;
}

#navbar-home-button {
  font-family: "Noto Serif";
  font-stretch: expanded;
  letter-spacing: 4px;
  border: 2px solid white;
  font-weight: 300;
  font-size: 25px;
  color: white;
  text-decoration: none;
  padding: 2px;

  transition: all 0.2s ease-in;
}

.navbar-hide-button #navbar-home-button,
.navbar-home-page #searchbar {
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
}

.no-preview,
.scrolled,
.navbar-other {
  visibility: visible;
  background-color: white;
  border-bottom: 1px solid lightgray;
  color: black;
  transition: all 0.2s ease-in;
}

.no-preview #navbar-home-button,
.scrolled #navbar-home-button,
.navbar-other #navbar-home-button {
  border-color: black;
  color: black;
  visibility: visible;
}

.no-preview button,
.scrolled button,
.navbar-other button {
  color: black;
  background-color: white;
}

.profile-dropdown {
  position: absolute;
  right: 20px;
  top: 50px;
  background-color: white;
  padding: 10px;
  margin: 5px 0;
}

.profile-dropdown button {
  color: black;
  background-color: white;
}

.scrolled #searchbar,
.scrolled #searchbar > input,
.scrolled #searchbar > i {
  visibility: visible;
}

#searchbar,
.scrolled #searchbar {
  border: 1px solid gray;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: absolute;
  left: 50vw;
  transform: translateX(-50%);
  margin: none;
  transition: color 0.2s ease-in, background-color 0.2s ease-in,
    border 0.2s ease-in, box-shadow 0.2s ease-in;
}

#searchbar > input,
.scrolled #searchbar > input {
  border: 0;
  font-size: 20px;
  background-color: transparent;
}

#searchbar > i,
.scrolled #searchbar > i {
  font-size: 24px;
  color: gray;
}

#searchbar > input:focus {
  outline: none;
}
