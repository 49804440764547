#linkedin,
#github {
  font-size: 30px;
  color: gray;
}

#linkedin:hover {
  color: #0077b5;
}

#github:hover {
  color: #333;
}

/* @import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;&display=swap"); */
#footer-home-button {
  font-family: "Noto Serif";
  font-stretch: expanded;
  letter-spacing: 4px;
  border: 2px solid black;
  font-weight: 300;
  font-size: 25px;
  color: black;
  text-decoration: none;
  padding: 2px;
}

footer {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
}

footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}
