#home-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

#home-title-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,200;1,200&display=swap");

#home-title {
  font-size: 180px;
  font-weight: 200;
  z-index: 0;
  color: white;
  border: 3px solid white;
  font-family: "Noto Serif";
  font-stretch: expanded;
  padding: 20px;
  letter-spacing: 24px;
}

#home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home-page-container > h2 {
  margin: 35px;
}
#home-page-container > p {
  margin: 0 150px;
}
