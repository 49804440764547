#search-container {
  position: absolute;
  top: 70px;
  left: 50vw;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 15;
  width: 300px;
}

#search-results {
  display: flex;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  background-color: white;
  padding: 10px;
}

.search-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 65px;
  cursor: pointer;
  background-color: white;
  z-index: 20;
  padding-right: 10px;
}

.search-result > img,
.search-result > video,
.search-result > .no-preview {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  border: none;
}

.search-result:hover {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
