.progress-bar-container {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  width: 100%;
  height: 20px;
}

.progress-bar[value] {
  -webkit-appearance: none;
  appearance: none;
}

.progress-bar[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.progress-bar[value]::-webkit-progress-value {
  border-radius: 0px;
  background-color: rgb(0, 0, 0);
  transition: ease-in-out 0.5s;
}
