#preview > video,
#preview > img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

#preview {
  width: 100%;
  height: 100vh;
}

.preview-item {
  background: rgb(209, 209, 209);
  background: radial-gradient(
    circle,
    rgba(209, 209, 209, 1) 0%,
    rgba(83, 83, 83, 1) 100%
  );
}

#gallery-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.big-container {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.small-container {
  height: fit-content;
  position: relative;
  margin: 50px 0;
  left: 0;
}

.big {
  color: white;
}

.small {
  color: black;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,200;1,200&display=swap");

#gallery-title {
  font-size: 10vw;
  font-weight: 200;
  z-index: 0;
  font-family: "Noto Serif";
  font-stretch: expanded;
  padding: 20px;
  letter-spacing: 2vw;
  text-align: center;
}

#gallery-date {
  font-size: 8vw;
  font-weight: 100;
  z-index: 0;
  font-family: "Noto Serif";
  font-stretch: expanded;
  padding: 20px;
  letter-spacing: 2vw;
}

.small #gallery-title,
.small #gallery-date {
  color: black;
}
#main-item-container {
  width: 95%;
}

.main-item {
  width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

#main-item-name {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  margin: 20px 0;
}

#items-container {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

#items-container > div {
  width: 100%;
}

.item {
  width: 100%;
}
