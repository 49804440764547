/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
ul,
ol,
li,
div,
header,
nav,
video {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
}

ul,
ol,
li {
  list-style: none;
}

button {
  color: white;
  background-color: black;
  border: 0;
  margin: 4px;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  padding: 7px;
  border-radius: 7px;
}

input::file-selector-button {
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}

input::file-selector-button:hover,
button:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 8px;
}

button:disabled {
  cursor: not-allowed;
  background-color: rgb(165, 50, 50);
  color: white;
}

textarea {
  resize: vertical;
}

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

a,
a:visited {
  color: black;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: rgb(48, 48, 255);
  transition: all 0.3s ease-in-out;
}
