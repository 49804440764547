.follow-user {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

.follow-user:hover {
  cursor: pointer;
}

.follow-user > p {
  font-size: 20px;
}

.profile-pic-small {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px;
}
